body {
  position: relative;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
  background-color: #e9ecef;
  color: #343a40;
}

.titleText {
  font-weight: 700;
  font-size: 24pt;
}

.calcContainer {
  margin-top: 5%;
  max-width: 600px;
}

.copy {
  font-size: 11pt;
  font-weight: 700;
}

.line {
  width: 180px;
  height: 10px;
  background: #395EB5;
}

.footer {
  margin-left: 0px;
  padding-left: 0px;
  margin-top: 10%;
}

a.footerLink {
  color: #343a40;
}

a.footerLink:hover {
  color: #395EB5;
}
